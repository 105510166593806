import React from "react"
import Header from "./header"
import { MDXProvider } from "@mdx-js/react"
import Link from "./ExternalLink"
import FileCaption from "./Caption"
import { siteWidth } from "../lib/css-recipes"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <MDXProvider
      components={{
        a: Link,
        FileCaption,
      }}
    >
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <Header />
        <main className={`${siteWidth} my-10`}>{children}</main>
      </div>
    </MDXProvider>
  )
}

export default Layout
