import React, { useState } from "react"
import { Link } from "gatsby"

import GithubIcon from "../assets/svg/icons8-github.svg"
import TwitterIcon from "../assets/svg/icons8-twitter.svg"
import Emailicon from "../assets/svg/inbox-solid.svg"
import MenuIcon from "../assets/svg/bars-solid.svg"
import CancelIcon from "../assets/svg/times-solid.svg"
import LinkedInIcon from "../assets/svg/linkedin-brands.svg"
import { siteWidth } from "../lib/css-recipes"

const MarkoLogo = () => (
  <div className=" transform mr-4 ">
    {/* <h className="marko text-white mr-3">Blog of</h> */}
    <h1 className="marko ">Marko Bilal</h1>
  </div>
)

const social = "h-6 w-6"

export default function Header() {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)
  return (
    <nav className="w-full border-b border-gray-200  text-primary relative">
      <div
        className={`flex flex-col flex-wrap  relative py-5 max-h-16 ${siteWidth}`}
      >
        <div className="flex justify-between flex-wrap items-center flex-1">
          <DesktopMenu />
          <div className="flex lg:hidden">
            <MarkoLogo />
          </div>
          <div className="flex lg:hidden cursor-pointer">
            {open ? (
              <CancelIcon className="h-5 w-5" onClick={toggle} />
            ) : (
              <MenuIcon className="h-5 w-5" onClick={toggle} />
            )}
          </div>
        </div>
      </div>
      {open && <MobileMenu toggle={toggle} />}
    </nav>
  )
}

function MobileMenu({ toggle }) {
  return (
    <div className="flex flex-col flex-wrap z-10 bg-gray-100 opacity-90 absolute w-full px-6 h-screen left-0 ">
      <NavItem>
        <Link onClick={toggle} className="no-underline" to="/">
          posts
        </Link>
      </NavItem>
      {/* <NavItem onClick={toggle}>Projects</NavItem> */}
      <NavItem>
        <Link onClick={toggle} className="no-underline" to="/about">
          about
        </Link>
      </NavItem>
      <SocialLinks />
    </div>
  )
}

function DesktopMenu() {
  return (
    <>
      <div className="hidden lg:flex">
        <MarkoLogo />

        <NavItem>
          <Link className="no-underline" to="/">
            posts
          </Link>
        </NavItem>
        {/* <NavItem>Projects</NavItem> */}
        <NavItem>
          <Link className="no-underline" to="/about">
            about
          </Link>
        </NavItem>
        {/* <NavItem>
          <Link className="no-underline" to="/subscribe">
            Subscribe
          </Link>
        </NavItem> */}
      </div>
      <div className="hidden lg:flex">
        <SocialLinks />
      </div>
    </>
  )
}

function SocialLinks({ toggle }) {
  return (
    <>
      <NavItem>
        <a
          className="no-underline"
          href="https://www.github.com/marko911"
          target="_blank"
          rel="noreferrer"
        >
          <GithubIcon className={`hidden md:inline ${social}`} />
          <span className="md:hidden"> Github</span>
        </a>
      </NavItem>
      <NavItem>
        <a
          className="no-underline"
          href="https://twitter.com/markobilal"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon className={`hidden md:inline ${social}`} />
          <span className="md:hidden"> Twitter</span>
        </a>
      </NavItem>
      <NavItem>
        <a
          className="no-underline"
          href="https://www.linkedin.com/in/marko-bilal-69023619a/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon className={`hidden md:inline ${social}`} />
          <span className="md:hidden"> LinkedIn</span>
        </a>
      </NavItem>
      <NavItem>
        <a
          className="no-underline"
          href="mailto:marko.bilal@hey.com"
          target="_blank"
          rel="noreferrer"
        >
          <Emailicon className={`hidden md:inline ${social}`} />
          <span className="md:hidden"> Email me</span>
        </a>
      </NavItem>
    </>
  )
}

function NavItem({ children }) {
  return (
    <div className=" flex md:mx-3 py-2 md:py-0 font-hs  font-bold md:font-medium lowercase  text-xl  md:text-base  ">
      {children}
    </div>
  )
}
